@mixin hero_section($hero){
  background-image: url("../img/heros/" + $hero + "/section_1.png"), radial-gradient(circle, transparent, $dark);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  &:hover{
    background-color: map-get($heros, $hero);
    background-image: url("../img/heros/" + $hero + "/section_2.png"), radial-gradient(circle, transparent, $dark);
  }
}

@mixin box-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $color: $dark) {
  box-shadow: $x-axis $y-axis $blur $color;
}
