// BEBAS NEUE
// --------------------------------------------------

@font-face {
  font-family: "bebas_neue_regularregular";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/bebasneue_regular-webfont.woff2") format("woff2"), url("../fonts/bebasneue_regular-webfont.woff") format("woff");
}

// BOLD
@font-face {
  font-family: "bebas_neuebold";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/bebasneue_bold-webfont.woff2") format("woff2"), url("../fonts/bebasneue_bold-webfont.woff") format("woff");
}

body{
  overflow-x: hidden;
  background: $white url("../img/backgrounds/body.png");
}

.logo{
  width: 5vw;
  height: 5vh;
  background-color: $white;
  mask: url("../img/brand/logo.svg") no-repeat center;
  &:hover{
    background-color: $secondary;
  }
}

.main-nav{
  margin-left: auto !important;
  .nav-item{
    margin: 0 .5vw;
    font-size: .8rem;
  }
}

hr{
  border-top: 5px solid rgba($dark, 1);
}

.no-up{
  margin-top: 0;
}

h2,
.upper{
  text-transform: uppercase;
}

.modal{
  &.cover-modal{
    background: rgba($dark, .9);
    .close{
      font-size: 3.5rem;
    }
    .modal-header{
      border-bottom: 0;
    }
    .modal-content{
      background: rgba($dark, 0) !important;
      border: 0;
      box-shadow: 0;
    }
  }
  .modal-dialog{
    .modal-content{
      color: $white;
      background: rgba($dark, .9);
    }
  }
}

main{
  .full-section{
    min-height: 100vh;
    padding: 0;
    background-image: url("http://via.placeholder.com/1920x1080");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    // .background-video{
    //   position: absolute;
    //   right: 0;
    //   bottom: 0;
    //   min-width: 100%;
    //   min-height: 100%;
    // }
  }
  //intro section
  .intro-section{
    min-height: 20vh;
    padding-top: 10vh;
    margin-bottom: 10vh;
    background-color: $secondary;
    transform: skew(0deg, -2deg);
    .content{
      transform: skew(0deg, 2deg);
    }
  }
  .service-section{
    padding-bottom: 10vh;
    .service{
      margin: 2vh 0;
    }
    .card{
      height: 100%;
      color: $white;
      background: $dark;
      border: 1rem solid $white;
      border-radius: 0;
      .card-img-top{
        width: auto;
        max-height: 10rem;
      }
      .content{
        padding: 5vh 5vw;
      }
    }
  }
  //angled section
  .angled-section{
    padding: 0;
    margin-top: -5vh;
    margin-bottom: 5vh;
    color: $white;
    background-color: $dark;
    border-top: 1rem solid $white;
    border-bottom: 1rem solid $white;
    transform: skew(0deg, 2deg);
    &:nth-child(odd) {
      transform: skew(0deg, -2deg);
      .content{
        transform: skew(0deg, 2deg);
      }
    }
    .content{
      padding: 10vh 5vw;
      transform: skew(0deg, -2deg);
    }
  }
  //hero service sections
  @each $color, $value in $heros-colors{
    .#{$color}-section {
      //min-height: 90vh;
      margin: 5vh 0;
      background: url("../img/heros/" + $color + "/service.png") no-repeat, $value;
      background-size: contain;
    }
  }
  .services-section{
    &:nth-child(even) {
      background-position: right bottom;
      @include media-breakpoint-down(sm) {
        background-position: center;
        background-size: cover;
      }
    }
    &:nth-child(odd) {
      background-position: left bottom;
      @include media-breakpoint-down(sm) {
        background-position: center;
        background-size: cover;
      }
      .row{
        justify-content: flex-end;
      }
    }
  }
}

footer{
  padding: 1vh 0;
  .container{
    section{
      padding: 1vh 0;
    }
    .social{
      font-size: 2rem;
    }
  }
}

//cookie

.alert-cookie {
  position: fixed;
  bottom: 0;
  left: 50%;
  display: none;
  padding: 2vh 5vw;
  margin: 2.5vh auto;
  border: 1px solid transparent;
  border-radius: .25rem;
  transform: translate(-50%);
  p {
    margin-bottom: 0;
  }
}

// hero page

.hero-page{
  @each $color, $value in $heros-colors{
    &.#{$color}-page {
      .landing-section {
        min-height: 80vh;
        background: url("../img/heros/" + $color + "/landing.gif"), url("../img/heros/" + $color + "/background.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: contain;
        @include media-breakpoint-down(md) {
          background-size: cover;
        }
      }
      .angled-section{
        background-color: $value;
      }
      .story-section{
        padding-top: 5vh 0;
        margin-top: -5vh;
        background: url("../img/heros/" + $color + "/parallax.png"), url("../img/heros/" + $color + "/pattern.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;
        .logo-row{
          margin-bottom: 2vh;
        }
        .mission{
          margin-bottom: 2vh;
        }
        .parallax{
          position: fixed;
          right: 0;
          bottom: 0;
          left: 15vw;
          z-index: -1;
          overflow: hidden;
          img {
            max-height: 100vh;
          }
        }
      }
      .skill-section{
        .skill{
          margin: 1.5vh 0;
        }
        .card{
          min-height: 100%;
          color: $white;
          background-color: $dark;
          transition: all 1s ease-in-out;
        }
      }
      .challenge-section{
        margin-top: 10vh;
        .card{
          margin-top: 2vh;
          @for $i from 1 through 4{
            &.challenge_#{$i}{
              background: url("../img/heros/" + $color + "/challenge_" + $i + ".png"), $dark;
              background-repeat: no-repeat;
              background-position: right;
              background-size: cover;
            }
          }
        }
      }
      .benefits-section{
        margin-top: 5vh;
        .sub-section{
          margin: 2vh 0;
        }
      }
      .benefits-heading{
        @include media-breakpoint-down(md) {
          margin-left: 75pt;
        }
        padding-left: 5vh;
      }
      .benefits-list{
        margin-top: -75pt;
        @include media-breakpoint-down(md) {
          margin-left: 75pt;
        }
        @include media-breakpoint-down(sm) {
          margin-left: 50pt;
        }
        list-style-image: url("../img/heros/point.png");
        li{
          display: none;
          margin: -25pt 0;
          font-size: 1.5rem;
          @include media-breakpoint-down(md) {
            font-size: 1.25rem;
          }
          @include media-breakpoint-down(sm) {
            margin: -38pt 0;
            font-size: 1rem;
          }
          &:first-of-type {
            list-style-image: url("../img/heros/" + $color + "/bullet-2.png");
          }
        }
      }
    }
  }

  .power-pane{
    h3{
      font-size: 31px;
    }
    p{
      font-family: "Open Sans Condensed", sans-serif;
      font-style: normal;
      font-weight: 200;
    }
  }


  .name-section{
    .row{
      padding: 2vh 0;
    }
  }
  .card-img-top{
    padding: 5vh 5vw;
  }
  .arrow{
    position: absolute;
    z-index: 10;
    margin-top: -15vh;
    margin-left: 15vh;
    @include media-breakpoint-down(md) {
      margin-right: 15vh;
    }
    .img-fluid{
      display: none;
    }
  }
}

//heros section
.heros-section{
  padding: 0 5vw;
  .hero-title{
    padding: 5vh 0;
  }
  .hero-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @each $color, $value in $heros-colors{
      .hero-#{$color} {
        background-image: url("../img/heros/" + $color + "/section_1.png"), radial-gradient(circle, transparent, $dark);
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        &:hover{
          background-color: $value;
          background-image: url("../img/heros/" + $color + "/section_2.png"), radial-gradient(circle, transparent, $dark);
        }
      }
    }
    // @each $color, $value in $theme-colors {
    //   .test-#{$color} {
    //     @include button-variant($value, $value);
    //   }
    // }
    .card{
      width: 15rem;
      min-height: 26rem;
      border: 6px solid $primary;
      border-radius: 0;
      box-shadow: 0 15px 30px -15px $primary;
      .card-img-top{
        padding: 1vh 1vw;
      }
    }
    h3{
      padding: 2vh 0;
    }
  }
}

//case studies

.caseStudy-section{
  padding: 2vh 5vw;
  margin-bottom: 10vh;
  .card{
    border: 0;
    @include box-shadow(5px, 5px, 5px, rgba($dark, .3));
    .card-img-overlay{
      display: flex;
      text-align: center;
      background-image: radial-gradient(circle, $dark, transparent);
      opacity: 0;
      transition: opacity 1s;
      .card-title{
        flex-direction: column;
        align-self: center;
        width: 100%;
      }
    }
    &:hover{
      .card-img-overlay{
        opacity: 1;
      }
    }
  }
}

.case-section{
  margin-bottom: 5vh;
  .card{
    margin: 1vh 0;
    .card-vid{
      height: 25rem;
    }
  }
}

.portfolio-section {
  padding: 2vh 5vw;
  margin-bottom: 10vh;
  .portfolio-item {
    margin: 1vh 0;
  }
  .card {
    border: 0;
    @include box-shadow(5px, 5px, 5px, rgba($dark, .3));

    .card-img-overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-image: radial-gradient(circle, $dark, transparent);
      opacity: 0;
      transition: opacity 1s;
    }

    &:hover {
      .card-img-overlay {
        opacity: 1;
      }
    }
  }
}

//news

.news-section{
  .jumbotron{
    margin-bottom: 0;
  }
  .page-section{
    padding: 2vh 1vw;
    background: linear-gradient(rgba($white, .5), rgba($white, .5)), url("../img/backgrounds/newsFlash.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 10px 10px 8px $dark;
    //border: .25rem solid $dark;
  }
  .main-article{
    background-image: linear-gradient(to right, $dark, rgba($dark, .75), transparent), url("../img/newsFlash/test.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .article-card{
    margin: 1vh 0;
    background: linear-gradient(to right, $white, rgba($white, .75), transparent);
    border: 0;
    //border-top: .25rem solid $dark;
    img{
      max-height: 20vh;
      object-fit: cover;
      object-position: 50% 0;
    }
  }
  .instagram-section{
    margin-bottom: 1vh;
    .img-thumbnail{
      height: 10rem;
      padding: 0;
      background-color: rgba($dark, 0);
      border: 2px solid $dark;
      border-radius: 0;
    }
  }
  .twitter-timeline{
    height: 40vh !important;
  }
  .social-section{
    hr {
      float: left;
      width: 75%;
      @include media-breakpoint-down(sm) {
        width: 25%;
      }
    }
  }
}

.publisher-image{
  float: right;
  max-width: 100%;
  max-height: 25vh;
  @include media-breakpoint-down(md) {
    max-height: 20vh;
  }
}

//forms

.contact-form{
  .form-group{
    .form-control{
      padding: .5rem 1rem;
      background-color: rgba($white, .5);
      border: none;
      border-radius: 0;
      @include box-shadow(5px, 5px, 5px, rgba($dark, .3));
      &:hover,
      :active{
        background-color: rgba($white, .9);
      }
    }
  }
}

//contact

.contact-section{
  margin-bottom: 5vh;
}

.map-section{
  .map{
    width: 100%;
    min-height: 60vh;
  }
}

// .angled-section{
//   a{
//     color: $white;
//     &:hover{
//       color: $secondary;
//     }
//   }
// }

//service page
.service-page{
  main{
    background-image: url("../img/backgrounds/services.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
}

.modal-xl{
  max-width: 1100px;
}

.parallax-window{
  min-height: 400px;
  background: transparent;
}

p,
small,
dd,
ol,
ul{
  font-family: "Open Sans Condensed", sans-serif;
}

dd{
  margin-left: .5rem;
}

dt{
  font-size: 1.5rem;
  dt{
    font-size: 1rem;
  }
}

.white-text {
  a {
    color: $white;
    &:hover {
      color: $secondary;
    }
  }
}

.invert{
  color: $white;
  &:hover{
    color: $secondary;
  }
}

.address{
  margin-left: -2rem;
  list-style: none;
}

.img-thumbnail{
  background-color: rgba($primary, 0);
  border: 0;
}

.hero-contact-btn{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  .btn{
    margin: 2vh 6vw;
  }
}
